import { ReactElement, useCallback, useState } from "react";
import { Check, Copy } from "react-feather";

interface CopyInputProps {
  value: string
}

const CopyInput = ({
  value
}: CopyInputProps): ReactElement => {
  const [isCopied, setIsCopied] = useState(false);

  const onCopy = useCallback(() => {
    navigator.clipboard.writeText(value)
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000)
  }, [value])

  return <>
    <div className="relative cursor-pointer" onClick={onCopy}>
      <input type="text" readOnly value={value} className="w-full !pr-10" />
      <div className="absolute right-2 top-0 bottom-0 cursor-pointer">
        <Copy className={`w-5 h-5 absolute top-1/2 -translate-y-1/2 right-0 duration-150 transform ${!isCopied ? "opacity-100 scale-100" : "  scale-50 opacity-0"}`}/>
        <Check className={`w-5 h-5 absolute top-1/2 -translate-y-1/2 right-0 text-primary duration-150 pointer-events-none transform ${isCopied ? "scale-100 opacity-100" : "scale-[2] opacity-0"}`} />

      </div>
    </div>
  </>
}


export {CopyInput};