'use client'
import Link from 'components/router/Link'
import Button from 'components/ui/basic/Button'
import React, { ComponentType, useState } from 'react'
import KalenderIcon from 'public/img/ci-icons/kalender.svg'
import AppleCalendar from 'public/img/calendar/apple-calendar.svg'
import GoogleCalendar from 'public/img/calendar/google-calendar.svg'
import { Copy } from 'react-feather'
import {
  gameCalenderPath,
  loginPath,
  signupPath,
  teamCalenderPath,
  tournamentCalenderPath
} from 'shared/urlHelpers'
import { ControlledOverlay } from './Overlay'
import { CopyInput } from './Copy'
import { useSession } from 'lib/session-client'
import { isAndroid } from 'react-device-detect'

export type CalendarImportOptionType = 'team' | 'league' | 'tournament' | 'game'
export interface CalendarImportOption {
  type: CalendarImportOptionType
  title: string
  id: string
}

const CalendarImport: ComponentType<{
  option: CalendarImportOption
  size: 'l' | 's'
  currentPath?: string
  inlineOverlay?: boolean // needs to be set to true, if overlay will be rendered as a child of any inline-container parent (used for container queries. e.g. @container/...)
}> = ({ option, size, currentPath, inlineOverlay }) => {
  const [session] = useSession()
  const [overlayIsOpen, setOverlayIsOpen] = useState(false)
  const [othersVisible, setOthersVisible] = useState(false)

  function toggleOverlayIsOpen() {
    if (overlayIsOpen) {
      setOthersVisible(false)
    }
    setOverlayIsOpen(!overlayIsOpen)
  }
  if (option) {
    const className =
      size === 'l'
        ? 'w-full block btn-short !pl-0 !m-0 !py-3 @xl/schedule:justify-start'
        : ''
    return (
      <ControlledOverlay
        small
        overlayIsOpen={overlayIsOpen}
        setOverlayIsOpen={toggleOverlayIsOpen}
        title="Kalender abonnieren"
        inlineOverlay={inlineOverlay}
        trigger={
          <Button
            className={className}
            onClick={() => setOverlayIsOpen(!overlayIsOpen)}
            color={size === 'l' ? 'link' : 'primary'}
            small={size === 's'}
            iconOnly={size === 's'}
            tooltip="Kalender abonnieren"
          >
            <KalenderIcon className={size === 'l' ? 'ci-icon' : ''} />
            {size === 'l' && <span>Kalender abonnieren</span>}
          </Button>
        }
      >
        {!session && (
          <div className="text-sm text-gray-600">
            Jetzt{' '}
            <Link
              href={loginPath({ backTo: currentPath })}
              className="text-primary"
            >
              anmelden
            </Link>
            , um den Kalender zu abonnieren. <br />
            Du hast noch kein Konto?{' '}
            <Link href={signupPath()} className="text-primary">
              Registriere
            </Link>{' '}
            dich jetzt kostenlos.
          </div>
        )}
        {session && (
          <div className="max-h-full md:w-[32rem]">
            {!othersVisible && (
              <>
                <div className="space-y-4">
                  <div className="md:flex items-center justify-between flex-nowrap gap-4">
                    <div className="flex gap-2 w-full">
                      <Button className="flex-1 !px-2" color="primary-outline">
                        <Link
                          className="flex"
                          href={getUrlForType(option, 'google')}
                          openInNewWindow
                        >
                          <GoogleCalendar className="mr-1" /> Google
                        </Link>
                      </Button>
                      {!isAndroid && (
                        <Button
                          className="flex-1 !px-2"
                          color="primary-outline"
                        >
                          <Link
                            className="flex"
                            href={getUrlForType(option, 'apple')}
                            openInNewWindow
                          >
                            <AppleCalendar className="mr-1" /> Apple
                          </Link>
                        </Button>
                      )}
                      <Button
                        className="flex-1 !px-2"
                        color="primary-outline"
                        onClick={() => setOthersVisible((s) => !s)}
                      >
                        <Copy className="mr-1" /> Link
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="text-sm text-gray-600 mt-4">
                  {getInfoTextForType(option.type)}
                </div>
              </>
            )}
            {othersVisible && (
              <div className="space-y-2">
                <div className="font-bold">Link zum kopieren:</div>
                <CopyInput value={getUrlForType(option, 'other')} />
                <div className="text-sm text-gray-600">
                  Du nutzt keinen Kalender von Google oder Apple? Dann kopiere
                  den Link und gehe in deinen Kalender. Nutze dort die
                  Möglichkeit “Kalender abonnieren” (die Benennung kann
                  abweichen) und füge den Link von handball.net dort ein.
                </div>
              </div>
            )}
          </div>
        )}
      </ControlledOverlay>
    )
  }
  return null
}

function getInfoTextForType(type: CalendarImportOptionType) {
  let typeStr: string
  let eventUpdateStr: string
  switch (type) {
    case 'team':
      typeStr = 'alle Spiele dieses Teams'
      eventUpdateStr = 'Die Termine werden'
      break
    case 'league':
      typeStr = 'alle Spiele dieser Liga'
      eventUpdateStr = 'Die Termine werden'
      break
    case 'tournament':
      typeStr = 'alle Spiele dieses Wettbewerbs'
      eventUpdateStr = 'Die Termine werden'
      break
    case 'game':
      typeStr = 'dieses Spiel'
      eventUpdateStr = 'Der Termin wird'
      break
  }
  return (
    <span>
      Abonniere den Kalender und behalte <strong>{typeStr}</strong> im Blick!{' '}
      {eventUpdateStr} fortlaufend aktualisiert, sodass dein Kalender immer auf
      dem aktuellen Stand ist.
    </span>
  )
}

function getUrlForType(
  option: CalendarImportOption,
  calendarType: 'apple' | 'google' | 'other'
) {
  switch (option.type) {
    case 'team':
      return teamCalenderPath(option.id, calendarType)
    case 'league':
    case 'tournament':
      return tournamentCalenderPath(option.id, calendarType)
    case 'game':
      return gameCalenderPath(option.id, calendarType)
  }
}

export default CalendarImport
